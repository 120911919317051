@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Oswald-SemiBold';
  src: local('Oswald-SemiBold'), url(./assets/fonts/Oswald-SemiBold.ttf) format('truetype');
  font-display: swap
}


@font-face {
  font-family: 'Raleway-Black';
  src: local('Raleway-Black'), url(./assets/fonts/Raleway-Black.ttf) format('truetype');
  font-display: swap
}


@font-face {
  font-family: 'Lato-Regular';
  src: local('Lato-Regular'), url(./assets/fonts/Lato-Regular.ttf) format('truetype');
  font-display: swap
}


@font-face {
  font-family: 'Lato-Thin';
  src: local('Lato-Thin'), url(./assets/fonts/Lato-Thin.ttf) format('truetype');
  font-display: swap
}


