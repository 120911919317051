/* Aesthetics */
.row {
    min-height: 100%;
    position: relative;
  }
  
  
  /* Essentials for ken burns */
  .content{
    position: relative;
  }
  
  .kenburns-wrap {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width:100%;
    position: absolute;
    overflow: hidden;
    img.kenburns {
      
      position: absolute;
      top: -999px;
      bottom: -999px;
      left: -999px;
      right: -999px;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      overflow: hidden;
      margin: auto;
          animation: 115s ppb_kenburns linear infinite alternate;
    }
  }
  
  @keyframes ppb_kenburns{
      0% {
          transform: scale(1.3) translate(-10%, 10%);
      }
      25% {
          transform: scale(1) translate(0, 0);
      }
      50% {
          transform: scale(1.3) translate(10%, 10%);
      }
      75% {
          transform: scale(1) translate(0, 0);
      }
      100% {
          transform: scale(1.3) translate(-10%, 10%);
      }
  }